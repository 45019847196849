import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ModalRef } from '../modal/modal-ref';

@Component({
  template: `
    <fl-video
      [src]="src"
      [timeDisplay]="timeDisplay"
      (videoEnd)="handleVideoEnd()"
      (videoPlay)="handleVideoPlay()"
    ></fl-video>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VideoModalComponent {
  @Input() src: string;

  /** Whether or not to show the current time, total time or time left for the current media */
  @Input() timeDisplay = true;

  constructor(private modalRef: ModalRef<VideoModalComponent>) {
    this.modalRef.setResult({
      videoPlayed: false,
      videoEnded: false,
    });
  }

  handleVideoEnd(): void {
    const {
      videoPlayed,
    }: {
      videoPlayed: boolean;
    } = this.modalRef.getResult();
    this.modalRef.setResult({
      videoPlayed,
      videoEnded: true,
    });
  }

  handleVideoPlay(): void {
    const {
      videoEnded,
    }: {
      videoEnded: boolean;
    } = this.modalRef.getResult();
    this.modalRef.setResult({
      videoPlayed: true,
      videoEnded,
    });
  }
}
